import React from "react";
import './styles/footer.css';
import { Link } from "react-router-dom";

function Footer() {
    return (
        <>
            <div className="footer-container pt-5 pb-3">
                <div className="container">
                    <div className="row text-start">
                        <div className="col-md-3 mb-3">
                            <h5>Who We Are</h5>
                            <ul className="list-unstyled">
                                <li><Link to="/about">About</Link></li>
                                <li>Our Partners</li>
                            </ul>
                        </div>
                        <div className="col-md-4 mb-3">
                            <h5>What We Do</h5>
                            <ul className="list-unstyled">
                                <li><Link to="/services">DevOps as a Service</Link></li>
                                <li><Link to="/sre">24x7-SRE-Site-Reliability-Engineering-Operations</Link></li>
                                <li><Link to="/managed-services">Managed Services</Link></li>
                                <li><Link to="/manpower">Manpower and Technology Consulting</Link></li>
                                <li><Link to="/testing">Software Testing</Link></li>
                                <li><Link to="/cloud">Cloud Security Engineering</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-4 mb-3">
                            <h5>Contact</h5>
                            <ul className="list-unstyled">
                                <li><Link to="/contact">Contact Us</Link></li>
                                <li><Link to="/intern">Career</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="row text-center pt-4">
                        <div className="col">
                            <h5>Join Us!</h5>
                            <p>© InOutTek Solutions 2024</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
