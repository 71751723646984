import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, Navigate } from "react-router-dom";

import NavbarHeader from './components/NavbarHeader';
import Home from './components/Home';
import About from './components/About';
import Service from './components/Services';
import SRE from './components/SRE';
import ManagedServices from './components/ManagedServices';
import Manpower from './components/Manpower';
import Testing from './components/Testing';
import Cloud from './components/Cloud';

import CareerIntern from './components/CareerIntern';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { FloatingWhatsApp } from 'react-floating-whatsapp'



function App() {
  const phoneNumber = '9597863432'; // example phone number

  const handleClick = () => {
    // Create the WhatsApp URL link to open the chat
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank'); // Opens the URL in a new tab
  };
  const images = '/images/inoutteklogo.png'
  return (
    <div className="App">
      <FloatingWhatsApp
        phoneNumber={phoneNumber} // Replace with your WhatsApp phone number with country code
        accountName="Inouttek Solutions"
        onClick={handleClick}
        avatar={images}// Optional, your name or business name
        style={{
          position: 'fixed',
          bottom: '1px', // Position it 10px from the top
          right: '1%', // Position it 10px from the right
          transform: 'scale(0.8)', // Make the button smaller
          zIndex: 9999, // Ensure it is on top of other elements
          width: '5%', // Adjust the size of the button
          height: '5%', // Adjust the size of the button
        }}
      />
      <NavbarHeader />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path='/about' element={<About />} />


        <Route path="/services" element={<Service />} />
        <Route path="/sre" element={<SRE />} />
        <Route path="/managed-services" element={<ManagedServices />} />
        <Route path="/manpower" element={<Manpower />} />

        <Route path="/testing" element={<Testing />} />

        <Route path="/cloud" element={<Cloud />} />

        <Route path='/intern' element={<CareerIntern />} />

        <Route path='/contact' element={<Contact />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />

    </div>
  );
}

export default App;
