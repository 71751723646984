import React,{useEffect} from "react";
import './styles/testing.css'
function Testing() {
    useEffect(() => {
        document.documentElement.scrollTop = 0;
    });
    return (
        <>
            <div className="container row servicesHeadingContainer">
                <div className="col"></div>
                <div className="col-lg-6 p-3 p-lg-5 text-start">
                    <h1 className="mt-3 servicesHeading" style={{ fontFamily: "outfit" }}>Software Testing</h1>
                    <p className="mt-3 fs-5">
                        Our Software Testing services are designed to ensure that your software applications meet the highest standards of quality, performance, and reliability. By applying a comprehensive approach to testing, we help identify and resolve issues before they reach your end users, ensuring a seamless and satisfying user experience.                    </p>
                </div>
                <div className="col-lg-5 ps-5 m2-3 ">
                    <img src="./images/Services/testing.jpg" alt="" width="100%" />
                </div>
            </div>




            <div className="container-fluid text-start  mt-5 col-lg-12  p-md-5 servicesTestingConatiner"  >

                <h1 className="p-3" style={{ fontFamily: "outfit", textAlign: "start" }}>
                    Our comprehensive testing service provides
                </h1>
                <div className="row mt-lg-4 p-4" style={{ columnGap: 10, rowGap: 50, justifyContent: "space-around" }}>
                    <div className="col-lg-3 col-md-5  p-4 pt-5 servicesTestingBox">
                        <h3 className="text-center">
                            Functional Testing
                        </h3>
                        <p className="text-start mt-4 ms-4 p-md-3">
                            Verifying that your software functions as expected and meets all specified requirements, ensuring that all features work correctly.
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-5 pt-5 p-4 servicesTestingBox">
                        <h3 className="text-center">
                            Regression Testing
                        </h3>
                        <p className="text-start mt-4 ms-4 p-md-3">
                            Testing existing functionalities to ensure that new updates or changes haven’t introduced any defects or issues.
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-5 pt-5  p-4 servicesTestingBox">
                        <h3 className="text-center">
                            Performance Testing
                        </h3>
                        <p className="text-start mt-4 ms-4 p-md-3">
                            Assessing your software’s performance under various conditions to identify potential bottlenecks and ensure scalability and responsiveness.
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-5  pt-5 p-3  p-md-4 servicesTestingBox">
                        <h3 className="text-center pt-4">
                            User Experience (UX) Testing
                        </h3>
                        <p className="text-start mt-4 ms-4 ">
                            Evaluating the usability and overall user experience to ensure your software is intuitive and meets user expectations.
                        </p>
                    </div>

                    <div className="col-lg-3 col-md-5 pt-5 p-3 p-md-4 servicesTestingBox">
                        <h3 className="text-center pt-4">
                            Automated Testing
                        </h3>
                        <p className="text-start mt-4 ms-4 ">
                            Utilizing automation tools to streamline repetitive test cases, increase test coverage, and accelerate the testing process.
                        </p>
                    </div>

                    <div className="col-lg-3 col-md-5 pt-5  p-md-4 servicesTestingBox">
                        <h3 className="text-center pt-4">
                            Compatibility Testing
                        </h3>
                        <p className="text-start p-2 mt-4 ms-4 ">
                            Ensuring that your software performs consistently across different devices, browsers, and operating systems.

                        </p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Testing;