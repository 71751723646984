import React, { useEffect } from "react";

function About() {
    useEffect(() => {
        document.documentElement.scrollTop = 0;
    });
    return (
        <>
            <div className="HomeContainerAboutWhy container-fluid row p-4 p-lg-5 " style={{ textAlign: "left", justifyContent: "space-between", alignItems: "", display: "flex", overflowX: "hidden" }} >
                <div className=" col-md-5 py-5 " >
                    <h1 className="ps-lg-4 fs-1" style={{ fontFamily: "outfit"}}>About Us</h1>
                    <p className="ps-lg-4 pe-2 fs-5">
                        InOutTek Solutions. We specialize in delivering tailored software
                        development, IT consulting, and cloud solutions designed to meet the
                        unique needs of our clients. With a team of experienced
                        professionals, we are committed to excellence in every project,
                        ensuring that our clients achieve their goals through cutting-edge
                        technology and strategic insights.
                    </p>
                </div>
                <div className="col-md-5  pb-4">
                    <img src="../images/About/aboutus.jpg" alt="" width="90%" />
                </div>

            </div>


            <div className="HomeContainerAboutWhy container-fluid row p-4 p-lg-5 d-flex flex-column-reverse flex-md-row" style={{ textAlign: "left", justifyContent: "space-between", alignItems: "", display: "flex", overflowX: "hidden" }} >
                <div className=" col-md-4 py-5 " >
                    <img src="../images/About/aboutServices.jpg" alt="" width="100%" />

                </div>
                <div className="col-md-7">
                    <h1 className="ps-lg-4" style={{ fontFamily: "outfit" }}>Our Services</h1>
                    <p className="ps-lg-4 pe-2 fs-5">
                        At InOutTek Solutions, we specialize in delivering top-notch software development services that empower businesses to thrive in the digital age. Our offerings includes.Tailored software solutions designed to meet the specific needs and goals of your business.Implementing scalable cloud-based applications and services that enhance flexibility and reduce costs.Designing and integrating APIs to connect systems, enabling smooth data exchange and functionality.Offering ongoing support to ensure your software remains up-to-date, secure, and performing at its best.
                    </p>
                </div>

            </div>

            <div className="HomeContainerAboutWhy container-fluid row p-4 p-lg-5 " style={{ textAlign: "left", justifyContent: "space-between", alignItems: "", display: "flex", overflowX: "hidden" }} >
                <div className=" col-md-5 py-5 " >
                    <h1 className="ps-lg-4" style={{ fontFamily: "outfit"}}>Beyond software services</h1>
                    <p className="ps-lg-4 pe-2 fs-5">
                    InOutTek Solutions offers an engaging internship program tailored for college students, which aims to provide hands-on experience in several tech domains, such as DevOps, Full Stack Development, and Release Engineering. This free internship program is designed to support and develop future tech talent, offering practical project work and mentorship to equip students with real-world skills.
                    </p>
                </div>
                <div className="col-md-5  pb-4">
                    <img src="../images/About/intrenships.jpg" alt="" width="90%" />
                </div>

            </div>
        </>
    )
}
export default About;


{/* <div className="col-md-7 pb-4">


<h2 className=" ps-lg-5 " style={{ fontFamily: "outfit" }}>Why Choose Us?</h2>

</div> */}