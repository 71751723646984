import React, { useState, useEffect } from "react";
import './styles/home.css';
import './styles/font.css';
import { Carousel } from 'react-bootstrap';
import { Link } from "react-router-dom";

function Home() {
   
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <>
            <div className="HomeContainer container-fluid row mt-lg-5 px-lg-3  pt-5 pt-md-1">
                <div className="HomeContainerTitle col-lg my-3 mt-md-5 text-start">


                    <Carousel activeIndex={index}  controls={false} indicators={false} onSelect={handleSelect} interval={3000}>
                        <Carousel.Item>
                            <div>
                                <div className="p-3 p-md-5" style={{ fontFamily: "outfit" }}>
                                    <h1 style={{ color: "rgb(70, 28, 147)" }}>DevOps as a Service</h1>
                                    <h1 className="text-start">Transforming Development and Operations</h1>
                                </div>
                            </div>

                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="p-3 p-md-5" style={{ fontFamily: "outfit" }}>
                                <h1 style={{ color: "rgb(70, 28, 147)" }}>24x7 SRE</h1>
                                <h1 className="text-start">Your System,Our watch on 24/7 SRE Expertise</h1>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="p-3 p-md-5" style={{ fontFamily: "outfit" }}>
                                <h1 style={{ color: "rgb(70, 28, 147)" }}>Managed Services</h1>
                                <h1 className="text-start">Your Success, Our Responsibility Managed Services Excellence</h1>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="p-3 p-md-5" style={{ fontFamily: "outfit" }}>
                                <h1 style={{ color: "rgb(70, 28, 147)" }}>Software Testing</h1>
                                <h1 className="text-start">Ensure your code performs flawlessly and delivers Excellence</h1>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="p-3 p-md-5" style={{ fontFamily: "outfit" }}>
                                <h1 style={{ color: "rgb(70, 28, 147)" }}>Cloud Security Engineering</h1>
                                <h1 className="text-start">Guardians of your Cloud Unparalleled Security Engineering </h1>
                            </div>
                        </Carousel.Item>
                    </Carousel>

                </div>
                <div className="col">

                    <Carousel activeIndex={index} controls={false} indicators={false} onSelect={handleSelect} interval={3000}>
                        <Carousel.Item>
                            <div className="image-container">
                                <img src="../images/Services/devops.jpg" alt="" width={"90%"} />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="image-container">
                                <img src="../images/Services/sre.jpg" alt="" width={"100%"} />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="image-container">
                                <img src="../images/Services/manageservice.jpg" alt="" width={"100%"} />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="image-container">
                                <img src="../images/Services/testing.jpg" alt="" width={"70%"} />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="image-container">
                                <img src="../images/Services/cloud.jpg" alt="" width={"100%"} />
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
            <div className="HomeContainerAboutWhy container-fluid row p-4 mx-1 p-lg-5 " style={{ textAlign: "left", display: "flex", justifyContent: "space-evenly", alignItems: "center", overflowX: "hidden", rowGap: 30, columnGap: 40 }} >
                <div className="HomeContainerAbout col-lg-5 py-5 p-4" >
                    <h3 className="ps-lg-3" style={{ fontFamily: "outfit", paddingLeft: 0 }}>Design and Engineering</h3>
                    <p className="ps-lg-4 pe-2 fs-5">
                        Our Design and Engineering services are dedicated to delivering innovative, user-centric solutions that balance creativity and technical excellence. By seamlessly integrating design principles with advanced engineering practices, we create solutions that are not only functional but also visually engaging and scalable.
                    </p>
                    <Link to="/contact">      <button className="ms-lg-4 HomeContainerAboutButton">How can we help you?</button></Link>
                </div>

                <div className="HomeContainerWhy col-lg-5 py-5 p-4">
                    <h3 className="ps-lg-3" style={{ fontFamily: "outfit", paddingLeft: 0 }}>Work With Us	</h3>
                    <p className="ps-lg-4 pe-2 fs-5">we are always looking for passionate, talented individuals who are eager to make an impact in the fast-evolving tech landscape. Whether you’re a seasoned professional or just starting your journey, InOutTek Solutions offers opportunities to work on innovative projects, develop new skills, and collaborate with industry experts.</p>
                    <Link to="/intern">  <button className="ms-lg-4 HomeContainerAboutButton">Apply now</button></Link>
                </div>
            </div>

            <div className="container-fluid row py-5 HomeContainerCards" style={{ display: "flex", rowGap: 20, justifyContent: "space-around" }}>
                <h1 className="p-3 HomeContainerCardsHeading" style={{ fontFamily: "", textAlign: "center" }}>Our Services	</h1>

                <div className="col-lg-4 card-container">
                    <div className="card">
                        <div className="card-front">
                            <div className=" text-start p-md-3" >
                                <h3 className="">DevOps as a Service</h3>
                                Our DevOps as a Service (DaaS) provides a streamlined approach to managing your development and operations needs
                                <br />
                                <Link to="/services" className="homeCardBox">

                                    <button className="mt-5 btn btn-dark" >Learn more</button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-back">
                            <Link to="/services" className="homeCardBox">
                                <div className=" text-start p-lg-3" >
                                    <h3 className="">DevOps as a Service</h3>

                                    Our DevOps as a Service (DaaS) provides a streamlined approach to managing your development and operations needs

                                    <br />
                                    <button className="mt-5 btn btn-outline-light" >Learn more</button>

                                </div>
                            </Link>
                        </div>

                    </div>
                </div>


                <div className="col-lg-4 card-container">
                    <div className="card">
                        <div className="card-front">
                            <div className=" text-start p-3" >
                                <h3 className="">24x7 SRE</h3>
                                At InOutTek Solutions, our Site Reliability Engineering (SRE) services ensure that your applications and infrastructure are reliable, scalable, and performant.

                                <br />
                                <Link to="/sre" className="homeCardBox">

                                    <button className="mt-5 btn btn-dark" >Learn more</button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-back">
                            <Link to="/sre" className="homeCardBox">
                                <div className=" text-start p-3" >
                                    <h3 className="">24x7 SRE</h3>
                                    At InOutTek Solutions, our Site Reliability Engineering (SRE) services ensure that your applications and infrastructure are reliable, scalable, and performant.
                                    <br />
                                    <Link to="/sre" className="homeCardBox">

                                        <button className="mt-5 btn btn-outline-light" >Learn more</button>
                                    </Link>
                                </div>
                            </Link>
                        </div>

                    </div>
                </div>

                <div className="col-lg-4 card-container">
                    <div className="card">
                        <div className="card-front">
                            <div className=" text-start p-3" >
                                <h3 className="">Managed Services</h3>
                                Our Managed Services provide comprehensive, proactive support for your IT infrastructure, allowing you to focus on your core business while we handle the complexities of technology management.
                                <br />
                                <Link to="/managed-services" className="homeCardBox">

                                    <button className="mt-5 btn btn-dark" >Learn more</button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-back">
                            <Link to="/managed-services" className="homeCardBox">
                                <div className=" text-start p-3" >
                                    <h3 className="">Managed Services</h3>
                                    Our Managed Services provide comprehensive, proactive support for your IT infrastructure, allowing you to focus on your core business while we handle the complexities of technology management.                                    <br />
                                    <button className="mt-5 btn btn-outline-light" >Learn more</button>
                                </div>
                            </Link>
                        </div>

                    </div>
                </div>


                <div className="col-lg-4 card-container">
                    <div className="card">
                        <div className="card-front">
                            <div className=" text-start p-3" >
                                <h3 className="">Manpower & Tech Consulting</h3>
                                Our Technology Consulting services are designed to help you harness the full potential of technology to drive business success.                                <br />
                                <Link to="/manpower" className="homeCardBox">

                                    <button className="mt-5 btn btn-dark" >Learn more</button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-back">
                            <Link to="/manpower" className="homeCardBox">
                                <div className=" text-start p-3" >
                                    <h3 className="">Manpower & Tech Consulting</h3>
                                    Our Technology Consulting services are designed to help you harness the full potential of technology to drive business success.
                                    <br /> <button className="mt-5 btn btn-outline-light" >Learn more</button>
                                </div>
                            </Link>
                        </div>

                    </div>
                </div>




                <div className="col-lg-4 card-container">
                    <div className="card">
                        <div className="card-front">
                            <div className=" text-start p-3" >
                                <h3 className="">Software Testing</h3>
                                Our Software Testing services are designed to ensure that your software applications meet the highest standards of quality, performance, and reliability.
                                <br />
                                <Link to="/testing" className="homeCardBox">

                                    <button className="mt-5 btn btn-dark" >Learn more</button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-back">
                            <Link to="/testing" className="homeCardBox">
                                <div className=" text-start p-3" >
                                    <h3 className="">Software Testing</h3>
                                    Our Software Testing services are designed to ensure that your software applications meet the highest standards of quality, performance, and reliability.
                                    <br />
                                    <button className="mt-5 btn btn-outline-light" >Learn more</button>

                                </div>
                            </Link>
                        </div>

                    </div>
                </div>

                <div className="col-lg-4 card-container">
                    <div className="card">
                        <div className="card-front">
                            <div className=" text-start p-3" >
                                <h3 className="">Cloud Security Engineering</h3>
                                Our Cloud Security Engineering services are designed to protect your cloud infrastructure from threats and ensure the integrity of your data.
                                <br />
                                  <Link to="/cloud" className="homeCardBox">

                                    <button className="mt-5 btn btn-dark" >Learn more</button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-back">
                            <Link to="/cloud" className="homeCardBox">
                                <div className=" text-start p-3" >
                                    <h3 className="">Cloud Security Engineerin</h3>
                                    Our Cloud Security Engineering services are designed to protect your cloud infrastructure from threats and ensure the integrity of your data.
                                    <br />
                                    <button className="mt-5 btn btn-outline-light" >Learn more</button>

                                </div>
                            </Link>
                        </div>

                    </div>
                </div>




    
            </div >
        </>
    );
}

export default Home;
