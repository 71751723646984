import def from "ajv/dist/vocabularies/discriminator";
import React,{useEffect} from "react";

function SRE() {
    useEffect(() => {
        document.documentElement.scrollTop = 0;
    });
    return (
        <>
            <div className="container row my-5 servicesHeadingContainer">
                <div className="col"></div>
                <div className="col-lg-6 p-3 p-lg-5 text-start">
                    <h1 className="mt-3 servicesHeading" style={{ fontFamily: "outfit" }}>24x7 SRE</h1>
                    <p className="mt-3 fs-5">
                        Our Site Reliability Engineering (SRE) Operations service is designed to ensure the reliability, scalability, and performance of your critical systems. By applying software engineering principles to operations, we help you achieve high availability and optimal performance for your services and applications.                    </p>
                </div>
                <div className="col-lg-5 ps-5 m2-3">
                    <img src="../images/Services/sre.jpg" alt="" width="100%" />
                </div>
            </div>

            <div className="row p-lg-5 servicesKey mt-5   p-3">

                <div className="container-fluid  text-start  col-lg-12   " >

                    <div className="row mt-lg-4 p-4" style={{ columnGap: 10, rowGap: 50, justifyContent: "space-around" }}>
                        <div className="col-7" style={{
                            display
                                : "flex", alignItems: "center", justifyContent: "center"
                        }}>
                            <h1 style={{ fontFamily: "outfit", fontSize: "3rem" }}>
                                Key Features
                            </h1>
                        </div>
                        <div className="col-lg-3 p-4 col-md-5 p-lg-4 servicesdevopsKey" style={{ borderColor: "red" }}>
                            <h3 className="text-center">
                                Reliability and Uptime
                            </h3>
                            <p className="text-start mt-4 p-4">
                                Implement strategies and tools to maximize system uptime and reliability, ensuring your services are always available to users.
                            </p>
                        </div>
                        <div className="col-lg-3 p-5 col-md-5  servicesdevopsKey" style={{ borderColor: "blue" }}>
                            <h3 className="text-center">
                                Performance Monitoring
                            </h3>
                            <p className="text-start mt-4 p-2">
                                Continuous monitoring of system performance to identify and resolve issues before they impact users.
                            </p>
                        </div>
                        <div className="col-lg-3 p-5 col-md-5  p-4 servicesdevopsKey" style={{ borderColor: "green" }}>
                            <h3 className="text-center">
                                Incident Management
                            </h3>
                            <p className="text-start mt-6 p-2 ">
                                Proactive incident response and management to quickly address and mitigate any operational issues.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-5  p-4 pt-5 servicesdevopsKey" style={{ borderColor: "yellow" }}>
                            <h3 className="text-center mt-2">
                                Automation
                            </h3>
                            <p className="text-start mt-2 p-4">
                                Automate routine tasks and processes to reduce manual work and improve efficiency.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-5 p-3 pt-5  p-md-5 servicesdevopsKey" style={{ borderColor: "orange" }}>
                            <h4 className="text-center">
                                Scalability
                            </h4>
                            <p className="text-start mt-4 p-2">
                                Design and implement scalable infrastructure to handle growing user demands and traffic.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-5 p-5 servicesdevopsKey">
                            <h4 className="text-center">
                                Root Cause Analysis
                            </h4>
                            <p className="text-start mt-4 p-2 ">
                                Perform thorough analysis to identify the root causes of incidents and implement long-term solutions.
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-5 p-4 pt-5 servicesdevopsKey" style={{ borderColor: "purple" }}>
                            <h4 className="text-center fs-lg-2">
                                Collaboration and Communication
                            </h4>
                            <p className="text-start mt-4 p-4 ">
                                Foster strong communication and collaboration between development and operations teams to enhance overall system performance.
                            </p>
                        </div>
                    </div>

                </div>
            </div>


            <div className="row p-lg-5 servicesBenifits p-2 p-md-3">

                <div className="container-fluid text-start   col-lg-12  p-md-5 "  >

                    <h1 className="p-3" style={{ fontFamily: "outfit", fontSize: "3rem", textAlign: "center" }}>
                        Benefits
                    </h1>
                    <div className="row mt-lg-4 p-4" style={{ columnGap: 10, rowGap: 50, justifyContent: "space-around" }}>
                        <div className="col-lg-3 col-md-5  p-4 pt-5 servicesdevopsBenifts">
                            <h3 className="text-center">
                                Enhanced Reliability
                            </h3>
                            <p className="text-start mt-4 p-2">
                                Ensure your systems are reliable and resilient to failures.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-5 pt-5 p-4 servicesdevopsBenifts">
                            <h3 className="text-center">
                                Improved Performance
                            </h3>
                            <p className="text-start mt-4 p-2">
                                Maintain optimal performance levels for your applications and services.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-5 pt-5  p-4 servicesdevopsBenifts">
                            <h3 className="text-center">
                                Reduced Downtime                            </h3>
                            <p className="text-start mt-4 p-2">
                                Minimize downtime and service disruptions through proactive monitoring and incident management.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-5  pt-5 p-3  p-md-4 servicesdevopsBenifts">
                            <h3 className="text-center pt-4">
                                Enhanced Reliability
                            </h3>
                            <p className="text-start mt-4 p-2 ">
                                Increase system reliability and availability by implementing best practices for monitoring, logging, and incident management.
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-5 pt-5 p-3 p-md-4 servicesdevopsBenifts">
                            <h3 className="text-center pt-4">
                                Scalable Solutions
                            </h3>
                            <p className="text-start mt-4 p-2 ">
                                Build and maintain infrastructure that can grow with your business needs.
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-5 pt-5  p-md-4 servicesdevopsBenifts">
                            <h3 className="text-center pt-4">
                                Cost Efficiency
                            </h3>
                            <p className="text-start p-2 mt-4 p-2">
                                Optimize operations and reduce costs through automation and efficient resource management.

                            </p>
                        </div>
                    </div>

                </div>
            </div>




       

        </>
    )
}

export default SRE;