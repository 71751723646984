import React, { useEffect } from "react";
import './styles/font.css';
import './styles/services.css';

function Service() {
    useEffect(() => {
        document.documentElement.scrollTop = 0;
    });
    return (
        <>
            <div className="container  py-3 row servicesHeadingContainer">
                <div className="col"></div>
                <div className="col-lg-6 p-3 p-lg-5 text-start">
                    <h1 className="mt-3 servicesHeading" style={{ fontFamily: "outfit" }}>DevOps as a Services</h1>
                    <h3 className="mt-4">Transforming Development and Operations</h3>
                    <p className="mt-3 fs-5">
                        Our DevOps as a Service (DaaS) offering provides a comprehensive solution to streamline and enhance your software development and IT operations. By leveraging cutting-edge tools and practices, we enable continuous integration, continuous delivery (CI/CD), and seamless collaboration between your development and operations teams.
                    </p>
                </div>
                <div className="col-lg-5 ps-5 m2-3">
                    <img src="../images/Services/devops.jpg" alt="" width="100%" />
                </div>
            </div>
            <div className="row p-lg-5 servicesKey  p-3">

                <div className="container-fluid  text-start  col-lg-12   " >

                    <div className="row mt-lg-4 p-4" style={{ columnGap: 10, rowGap: 50, justifyContent: "space-around" }}>
                        <div className="col-7" style={{
                            display
                                : "flex", alignItems: "center", justifyContent: "center"
                        }}>
                            <h1 style={{ fontFamily: "outfit", fontSize: "3rem" }}>
                                Key Features
                            </h1>
                        </div>
                        <div className="col-lg-3 p-4 col-md-5 p-lg-4 servicesdevopsKey" style={{ borderColor: "red" }}>
                            <h3 className="text-center">
                                End-to-End Automation
                            </h3>
                            <p className="text-start mt-4 p-2 ">
                                Automate the entire software development and deployment process, from code integration to production, reducing manual intervention and minimizing errors.
                            </p>
                        </div>
                        <div className="col-lg-3 p-5 col-md-5  p-4 servicesdevopsKey" style={{ borderColor: "blue" }}>
                            <h3 className="text-center">
                                Collaboration Tools
                            </h3>
                            <p className="text-start mt-4 p-2  ">
                                Utilize advanced collaboration tools to enhance communication and coordination between development, operations, and other stakeholders.
                            </p>
                        </div>
                        <div className="col-lg-3 p-5 col-md-5  p-4 servicesdevopsKey" style={{ borderColor: "green" }}>
                            <h3 className="text-center">
                                Security Integration
                            </h3>
                            <p className="text-start mt-6 mt-4 p-2  ">
                                Incorporate security practices into the DevOps workflow, ensuring that security is a fundamental aspect of the development and deployment process.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-5  p-4 pt-5 servicesdevopsKey" style={{ borderColor: "yellow" }}>
                            <h4 className="text-center">
                                Continuous Integration / Continuous Delivery
                            </h4>
                            <p className="text-start mt-4 p-2 ">
                                Implement robust CI/CD pipelines to ensure rapid, reliable, and repeatable software releases.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-5 p-3 pt-5  p-md-5 servicesdevopsKey" style={{ borderColor: "orange" }}>
                            <h4 className="text-center">
                                Infrastructure as Code
                            </h4>
                            <p className="text-start mt-4 p-2 ">
                                Manage and provision infrastructure using code, enabling consistent and reproducible environments across development, testing, and production.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-5 p-5 servicesdevopsKey">
                            <h4 className="text-center">
                                Scalability
                            </h4>
                            <p className="text-start mt-4 p-2  ">
                                Design and implement scalable infrastructure to handle varying workloads and support business growth.
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-5 p-4 pt-5 servicesdevopsKey" style={{ borderColor: "purple" }}>
                            <h4 className="text-center fs-lg-2">
                                Monitoring and Logging
                            </h4>
                            <p className="text-start mt-4 p-2  ">
                                Integrate comprehensive monitoring and logging solutions to gain real-time insights into system performance, enabling proactive issue resolution.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row p-lg-5 servicesBenifits p-2 p-md-3">

                <div className="container-fluid text-start   col-lg-12  p-md-5 "  >

                    <h1 className="p-3" style={{ fontFamily: "outfit", fontSize: "3rem", textAlign: "center" }}>
                        Benefits
                    </h1>
                    <div className="row mt-lg-4 p-4" style={{ columnGap: 10, rowGap: 50, justifyContent: "space-around" }}>
                        <div className="col-lg-3 col-md-5  p-4 pt-5 servicesdevopsBenifts">
                            <h3 className="text-center">
                                Faster Time to Market
                            </h3>
                            <p className="text-start mt-4 p-2">
                                Accelerate software development and deployment cycles, allowing your business to quickly respond to market changes and customer needs.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-5 pt-5 p-4 servicesdevopsBenifts">
                            <h3 className="text-center">
                                Improved Quality
                            </h3>
                            <p className="text-start mt-4 p-2">
                                Enhance the quality of your software through automated testing, continuous integration, and continuous delivery practices.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-5 pt-5  p-4 servicesdevopsBenifts">
                            <h3 className="text-center">
                                Operational Efficiency
                            </h3>
                            <p className="text-start mt-4 p-2">
                                Reduce operational overhead and improve efficiency through automation and streamlined workflows.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-5  pt-5 p-3  p-md-4 servicesdevopsBenifts">
                            <h3 className="text-center pt-4">
                                Enhanced Reliability
                            </h3>
                            <p className="text-start mt-4 ms-4 ">
                                Increase system reliability and availability by implementing best practices for monitoring, logging, and incident management.
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-5 pt-5 p-md-4 servicesdevopsBenifts">
                            <h3 className="text-center pt-4">
                                Scalability and Flexibility
                            </h3>
                            <p className="text-start mt-4 p-2">
                                Build and maintain scalable infrastructure that can adapt to changing business requirements and workloads.
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-5 pt-5  p-md-4 servicesdevopsBenifts">
                            <h3 className="text-center pt-4">
                                Cost Savings
                            </h3>
                            <p className="text-start p-2 mt-4  ">
                                Optimize resource utilization and reduce costs associated with manual processes and downtime.

                            </p>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Service;